<template>
  <div class="fill-height container--fluid" style="width: 100%">
    <div class="container--fluid">
      <v-toolbar tile flat style="max-height: 30px">
        <v-btn icon v-on:click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title v-on:click="goBack" style="cursor: pointer">Go back</v-toolbar-title>
        <v-spacer />

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small plain v-bind="attrs" v-on="on" icon @click="openDialog">
              <v-icon size="24px" color="primary">mdi-bookmark-check</v-icon>
            </v-btn>
          </template>
          <span>I'm interested</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small plain v-bind="attrs" v-on="on" icon @click="sendPreference('like')">
              <v-icon size="24px" color="red">mdi-heart</v-icon>
            </v-btn>
          </template>
          <span>I like</span>
        </v-tooltip>
      </v-toolbar>
    </div>
    <v-container fluid class="fill-height">
      <v-row align="start" justify="start">
        <v-col cols="12" md="5">
          <v-container fluid class="mx-0">
            <v-row no-gutters>
              <v-col cols="12" class="mb-1">
                <span class="text-h6 text-md-h5 font-weight-ligh headline text-uppercase">
                  {{ profile.name }}
                </span>
              </v-col>
              <v-row class="mb-1">
                <v-col cols="12" lg="6" md="12" sm="12">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" large @click="openReservationForm()"
                             style="width: 100%; background-color: #691e32; color: white"
                      >
                        <!-- <v-icon color="green" size="44px">mdi-plus</v-icon> -->

                        <img src="@/assets/images/cart.svg" alt="cart" style="
                        object-fit: contain;
                        width: 24px;
                        height: 24px;
                        margin-right: 5%;
                        filter: brightness(0) invert(1);
                      "/>

                        <h3 class="text-uppercase" style="color: white">
                          Add to itinerary
                        </h3>
                      </v-btn>
                    </template>
                    <span>Add to itinerary</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" lg="6" md="12" sm="12">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :href="`tel:${profile.phone1}`" v-bind="attrs" v-on="on" large style="width: 100%;"
                             color="success">
                        <!-- <v-icon color="green" size="44px">mdi-plus</v-icon> -->

                        <v-icon color="white" style="margin-right: 5%;">mdi-phone</v-icon>

                        <h3 class="text-uppercase" style="color: white">
                          Call us
                        </h3>
                      </v-btn>
                    </template>
                    <span>Call us</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-col cols="12" class="mb-1">
                <v-carousel cycle hide-delimiters :show-arrows="false">
                  <v-carousel-item v-for="item in profile.pictures" :key="item.id">
                    <img :src="item.url"
                         style="object-fit: cover; object-position: center; width: 100%; height: 100%"/>
                    <img v-show="profile.excellence" src="@/assets/images/icons/escudo_coepris.f8331f99.png" alt="sello"
                         class="superior-derecha"/>
                    <img v-show="profile.excellence" src="@/assets/images/icons/repssabi.png" alt="sello1"
                         class="superior-derecha-segunda"/>
                  </v-carousel-item>
                </v-carousel>
              </v-col>
              <v-col v-if="profile.excellence">
                <div style="display: flex; align-items: center;justify-content: center; height: 110px; width: 100%"
                     class="mt-5">
                  <!-- <a :href="profile.coepris_qr_link" target="_blank">
                  </a> -->
                  <img usemap="#bcycoepris" src="@/assets/images/bcycoepris.png" alt="" class="img-qr-rigth"/>
                  <map name="bcycoepris">
                    <area
                      shape="rect"
                      coords="0,0,240,110"
                      :href="profile.coepris_qr_link"
                      target="_blank"
                      alt="HTTP" />
                  </map>                  
                  <a :href="profile.coepris_qr_link" target="_blank" class="ms-2">
                    <img :src="profile.coepris_qr" alt="qr" class="img-qr"/>
                  </a>
                </div>
              </v-col> 
              <v-col cols="12">
                <h3 class="ms-2">FAQ's</h3>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>What is COEPRIS?</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      The COEPRIS (State Commission for Protection against Health Risks) in Mexico is a state-level
                      health authority responsible for regulating and ensuring the compliance of sanitary standards in
                      various sectors. Its main focus is on preventing and managing health risks related to food safety,
                      water quality, medical services, drugs, and general public health. COEPRIS works to protect the
                      population from health hazards by enforcing laws, conducting inspections, and promoting sanitary
                      practices.

                      <a target="_blank" href="https://www.gob.mx/cofepris">Go to COEPRIS</a>.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>What is the Secretary of Tourism?</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      The Secretaría de Turismo de Baja California is responsible for promoting the development of the
                      state’s tourism industry. Its mission is to formulate, implement, and evaluate plans and programs
                      that foster sustainable tourism, attract investment, generate employment, and improve the quality
                      of life for residents. The agency works to position Baja California as a competitive destination
                      both nationally and internationally, focusing on key areas such as eco-tourism, cultural tourism,
                      and gastronomic tourism ￼ ￼.
                      For more information, you can visit their official site <a target="_blank"
                                                                                 href="https://www.bajacalifornia.gob.mx/secture/Conocenos/Mision">here</a>
                      ￼.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>What is State Catalog of Excellence in Health and Wellness Tourism (CEETSB):?</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      CEETSB is a pioneering project developed by the Baja California Secretariat of Tourism (SECTURE). 
                      Its purpose is to identify and promote globally the highest quality health and wellness service 
                      providers in the region, complying with strict national and international standards of medical care 
                      and patient safety.

                      This initiative plays a crucial role in Baja California's strategy to promote the state as a leader 
                      in health and wellness tourism, allowing visitors to easily identify health establishments and professionals 
                      certified and accredited under current national and/or international health regulatory standards. 
                      
                      CEETSB also supports Baja California's status as the "Health Tourism Capital of Mexico", ensuring that 
                      both local residents and international visitors have access to safe and excellent medical and wellness 
                      services.

                      By being included in this catalog, health and wellness establishments and professionals demonstrate 
                      that they meet the highest standards of quality, care and service to the specialized market in health 
                      and wellness tourism with current international accreditations and certifications.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <strong>What is The State Registry of Health and Wellness Service Providers (REPSSABI)?</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      REPSSABI seeks to promote a culture of self-regulation in health care establishments through 
                      the accompaniment and health promotion by COEPRIS, helping to strengthen the best health practices, 
                      the quality and safety of the medical services provided in our region. Its purpose is to ensure 
                      that all health and wellness service providers in the state comply with strict safety, quality and 
                      regulatory standards.

                      By being included in this registry, establishments such as hospitals, medical clinics, dental offices, 
                      recovery centers and medical spas demonstrate that they comply with their legal and technical documentation 
                      that supports their legality and good practices in the state.

                      For more details, you can visit the official site: <a target="_blank"
                                                             href="https://tramites.bajacalifornia.gob.mx/tu_belleza_con_certeza_4abf9f93-214d-4f25-a107-860c644576df">REPSSABI</a>
                      official site.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12">
                <v-container class="pa-0 mt-4" fluid>
                  <div :id="containerID" :style="containerCSS"></div>
                  <v-btn depressed class="mt-1" style="background-color: #691e32; color: white"
                         block @click="goAddress">
                    <v-icon class="me-2">mdi-map-marker</v-icon>
                    How to get there
                  </v-btn>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
          <!--<v-row justify="start" align="start">
                        <v-card
                                class="d-flex fill-height fluid mt-5"
                                width="100%"
                                flat
                                elevation="0"
                                tile>
                            <v-img
                                    src="@/assets/images/mapa-location.png"
                                    transition="slide-x-transition"
                                    position="top"
                                    width="100%"
                                    height="400"
                                    max-height="400">
                                <v-toolbar class="ma-5" dense floating>
                                    <v-text-field
                                            hide-details
                                            prepend-icon="mdi-magnify"
                                            single-line>
                                    </v-text-field>
                                    <v-btn icon>
                                        <v-icon>mdi-crosshairs-gps</v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </v-img>
                        </v-card>
                    </v-row>-->
        </v-col>
        <v-col cols="12" md="7">
          <v-card elevation="0" class="ml-1">
            <v-container fluid class="mx-0">
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <span class="text-button font-weight-medium">Description:
                  </span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col>
                      <div class="d-flex align-start">
                        <v-icon style="color:  #691e32" class="me-2">mdi-format-quote-close</v-icon>
                        <div class="text-container" ref="descriptionSpan">
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{
                          profile.description_english
                              ? profile.description_english
                              : profile.description
                        }}
                      </span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">Address: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-map-marker</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.address }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">E-mail: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-email</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.email }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="text-button font-weight-medium">Phones: </span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-phone</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        <!--{{ profile.phone1 | formatPhone('MX') }}, {{ profile.phone2 || "NO" | formatPhone('US') }}-->
                        <span v-show="profile.phone!==null">{{ profile.phone | formatPhone("MX") }}, </span>
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span v-if="profile.website!==null" class="text-button font-weight-medium">Website:
                  </span>
                  <v-divider v-if="profile.website!==null"></v-divider>
                  <v-row v-if="profile.website!==null" align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-web</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        <a :href="profile.website" target="_blank">{{ profile.website }}</a>
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                 <span class="text-button font-weight-medium">Attention schedule:
                  </span>
                  <v-divider></v-divider>
                  <v-row align-content="stretch" justify="start">
                    <v-col class="d-flex align-start">
                      <v-icon style="color:  #691e32" class="me-2">mdi-clock</v-icon>
                      <span class="text-body-2 font-weight-ligh grey--text text--darken-1">
                        {{ profile.servicehours }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <span class="text-button font-weight-medium">Social media:</span>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="stretch" justify="start">
                    <v-col cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="blue" v-bind="attrs" v-on="on" icon :href="profile.facebookprofile"
                                 target="_blank">
                            <v-icon size="24px" color="primary">mdi-facebook</v-icon>
                          </v-btn>
                        </template>
                        <span>Facebook</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="#D93F8FFF" v-bind="attrs" v-on="on" icon :href="profile.instagramprofile"
                                 target="_blank">
                            <v-icon size="24px">mdi-instagram</v-icon>
                          </v-btn>
                        </template>
                        <span>Instagram</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="red" v-bind="attrs" v-on="on" icon :href="profile.youtubeprofile"
                                 target="_blank">
                            <v-icon size="24px">mdi-youtube</v-icon>
                          </v-btn>
                        </template>
                        <span>Youtube</span>
                      </v-tooltip>
                    </v-col>
                    <v-col>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="blue" v-bind="attrs" v-on="on" icon :href="profile.website" target="_blank">
                            <v-icon size="24px" color="primary">mdi-web</v-icon>
                          </v-btn>
                        </template>
                        <span>Website</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <div class="fill-height container--fluid justify-start mt-5" v-if="profile.youtubeprofile != null">
                    <iframe width="100%" :height="$vuetify.breakpoint.xs ? 300 : 400" :src="youtubeVideo"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container style="background-color: #691e32" fluid class="fill-height text-center mx-0">
      <v-row>
        <v-col cols="12">
          <v-container fill-height fluid class="ma-0">
            <v-row justify="center" align="center" class="mt-3 text-center">
              <v-col>
                <span class="text-h6 text-md-h4 text-uppercase white--text font-weight-bold">Our Best Transportations</span>
              </v-col>
            </v-row>
            <v-row class="mt-0 pt-0">
              <v-col cols="12">
                <div class="fill-height">
                  <v-slide-group center-active v-model="model" class="pa-0" active-class="blue lighten-4"
                                 next-icon="mdi-arrow-right-drop-circle-outline"
                                 prev-icon="mdi-arrow-left-drop-circle-outline"
                                 show-arrows>
                    <v-container fluid class="container--fluid">
                      <v-row justify="center">
                        <v-slide-item v-for="card in transports" :key="card.id" v-slot="{ active, toggle }">
                          <!--width="250"-->
                          <v-card v-if="card.pictures[0] != null" :color="active ? undefined : 'white'"
                                  class="ma-1 rounded-lg" height="auto"
                                  :width="$vuetify.breakpoint.mobile ? 300 : 300"
                                  elevation="10" @click="toggle">
                            <div v-on:click="getProfile(card.id)">
                              <v-img v-if="card.pictures[0] != null" :src="card.pictures[0].url" :lazy-src="
                                      require('@/assets/images/no-image.jpg')
                                    " class="white--text align-end justify-start rounded-t-lg" position="center"
                                     height="200px" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                              </v-img>
                              <div class="row">
                                <div class="col-12">
                                  <v-card-subtitle
                                      class="pb-0 text-subtitle-1 indigo--text text-capitalize text-truncate">
                                    {{ card.name }}
                                  </v-card-subtitle>
                                </div>
                              </div>
                              <v-card-actions class="mt-1 justify-center">
                                <v-btn v-show="card.facebook_profile!==null" color="blue" icon
                                       :href="card.facebook_profile" target="_blank">
                                  <v-icon size="24px" color="primary">mdi-facebook</v-icon>
                                </v-btn>
                                <v-btn v-show="card.instagram_profile!==null" color="#D93F8FFF" icon
                                       :href="card.instagram_profile" target="_blank">
                                  <v-icon size="24px">mdi-instagram</v-icon>
                                </v-btn>
                                <v-btn v-show="card.youtubeprofile!==null" color="red" icon :href="card.youtubeprofile"
                                       target="_blank">
                                  <v-icon size="24px">mdi-youtube</v-icon>
                                </v-btn>
                                <v-btn v-show="card.web_page!==null" color="blue" icon :href="card.web_page"
                                       target="_blank">
                                  <v-icon size="24px" color="primary">mdi-web</v-icon>
                                </v-btn>
                                <v-btn v-show="false" icon target="_blank">
                                  <i class="bi bi-twitter-x" style="font-size: 20px;"></i>
                                </v-btn>
                              </v-card-actions>
                            </div>
                          </v-card>
                        </v-slide-item>
                      </v-row>
                    </v-container>
                  </v-slide-group>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <footer-profile-component />

    <v-dialog transition="dialog-top-transition" v-model="reservationFormDialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Reservation times</span>
        </v-card-title>
        <v-card-text>
          <v-row class="mb-3">
            <v-col>
              <label>Reservation date</label>
              <v-dialog
                  ref="dialog1"
                  v-model="modal3"
                  :return-value.sync="reservation.date"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="reservation.date"
                      label="Select a date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="reservation.date"
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modal3 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog1.save(reservation.date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <!-- Campo de hora -->
          <v-row>
            <v-col>
              <label>Reservation hour</label>
              <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  :return-value.sync="reservation.hour"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="reservation.hour"
                      label="Select a hour"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="modal2"
                    v-model="reservation.hour"
                    full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modal2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(reservation.hour)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeReservationForm">Cancel</v-btn>
          <v-btn color="success" @click="addToCart(profile)">Add appointment</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="row--dense mx-1 mt-2 modal-email">
            <h4 class="mb-3">Please share your email with us and we will contact you shortly</h4>
            <v-text-field v-model="email" :rules="emailRules" :error="email===''" label="Email"
                          error-messages="Please enter an email" required>
            </v-text-field>
          </div>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeDialog"> Cancel</v-btn>
            <v-btn :disabled="!valid" color="primary" text @click="acceptDialog">
              Accept
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackBar" :timeout="2000">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="snackBar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import transportationsService from "@/providers/TransportationsService";
import FooterProfileComponent from "@/layouts/app-layout/footer/FooterProfileComponent";
import preferencesService from "@/providers/PreferencesService";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "TransportationProfileComponent",
  components: { FooterProfileComponent },
  props: {
    transport_id: Number,
    initialLocation: {
      type: Array,
      default: () => [-116.97480855890304, 32.547976944620714],
    },
    height: {
      type: String,
      default: "400px",
    },
    mapStyle: {
      type: String,
      default: "mapbox://styles/mapbox/outdoors-v11",
    },
    color: {
      type: String,
      default: "orange",
    },
    containerID: {
      type: String,
      default: "map",
    },
  },
  data: () => ({
    modal1:false,
    modal2:false,
    model: null,
    youtubeVideo: "",
    snackBar: false,
    snackText: "",
    mapBoxApiKey: process.env.VUE_APP_BHC_MAPBOX_API_KEY,
    valid: true,
    dialog: false,
    email: null,
    location: [],
    transports: [],
    profile: {
      id: 0,
      name: null,
      description: null,
      description_english: null,
      establishment_type: null,
      email: null,
      address: null,
      phone: null,
      website: null,
      map_lat: null,
      map_lng: null,
      facebookprofile: null,
      instagramprofile: null,
      youtubeprofile: null,
      servicehours: null,
      pictures: [],
    },
    emailRules: [
      (v) => !!v || "El Correo Electrónico es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido",
    ],

    reservationFormDialog: false,
    reservation: {
      category: "transportations",
      id: "",
      date: "",
      hour: "",
    },
  }),
  computed: {
    containerCSS() {
      return {
        width: "100%",
        height: this.height,
      };
    },
  },
  filters: {
    formatPhone(value) {
      if (value == "undefined" || value === null) {
        return "";
      }
      return (
        "(" +
        value.substring(0, 3) +
        ") " +
        value.substring(3, 6) +
        "-" +
        value.substring(6)
      );
    },
  },
  methods: {
    goAddress() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              this.userLocation = `${position.coords.latitude},${position.coords.longitude}`;
              this.openGoogleMaps();
            },
            (error) => {
              console.error("Error al obtener la ubicación:", error);
              console.log("No se pudo obtener la ubicación. Asegúrate de que los servicios de ubicación estén habilitados.");
            }
        );
      } else {
        console.log("La Geolocalización no es compatible con este navegador.");
      }
    },
    openGoogleMaps() {
      if (this.userLocation) {
        const url = `https://www.google.com/maps/dir/?api=1&origin=${this.userLocation}&destination=${this.profile.map_lat},${this.profile.map_lng}&travelmode=driving`;
        window.open(url, '_blank'); // Abre Google Maps en una nueva pestaña
      }
    },
    openReservationForm() {
      this.reservationFormDialog = true;
    },

    closeReservationForm() {
      this.reservationFormDialog = false;
    },

    //cart

    addToCart(profile) {
      // Validar que la fecha y la hora estén seleccionadas
      if (!this.reservation.date || !this.reservation.hour) {
        this.snackText = "Select a date and time for the reservation.";
        this.snackBar = true;
      } else {
        this.reservationFormDialog = false;

        this.reservation.id = profile.id;

        const transportation = {
          category: "Transport",
          name: profile.name,
          image: profile.pictures[0]?.url,
          profile: profile,
          reservation: this.reservation,
        };

        //   // Llama a la acción addToCart del store para agregar el doctor al carrito

        this.$store.dispatch("addToCart", transportation);

        this.snackText = "Reservation successfully added to itinerary.";
        this.snackBar = true;

        //   console.log(`agregado doctor ${doctor.profile.name}`);
      }
    },

    //////////////////////////////////////////

    goBack() {
      this.$router.push("/transportations");
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    loadURL() {
      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      const url = this.profile.youtubeprofile.split(
        /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/
      );
      // console.log("url", url);
      const YId =
        undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
      // console.log("YId", YId);
      if (YId === url[0]) {
        console.log("not a youtube link");
      } else {
        console.log("it's  a youtube video");
      }
      const topOfQueue = youtubeEmbedTemplate.concat(YId);
      // console.log("topOfQueue", topOfQueue);
      this.youtubeVideo = topOfQueue;
    },
    getTransports() {
      transportationsService.getViewList().then((record) => {
        this.transports = record.value;
      });
    },
    getProfile(idTransp) {
      let record = null;
      transportationsService.getRecord(idTransp).then((fetch_data) => {
        record = fetch_data.value;

        if (record !== null) {
          this.profile.id = record.id;
          this.profile.name = record.name;
          this.profile.description = record.description;
          this.profile.description_english = record.description_english;
          this.profile.address = record.address;
          this.profile.map_lat = record.map_lat;
          this.profile.map_lng = record.map_lng;
          this.profile.email = record.email;
          this.profile.establishment_type = record.establishment_type;
          this.profile.phone = record.phone;
          this.profile.facebookprofile = record.facebook_profile;
          this.profile.instagramprofile = record.instagram_profile;
          this.profile.youtubeprofile = record.youtube_video;
          this.profile.website = record.web_page;
          this.profile.servicehours = record.servicehours;
          this.profile.pictures = record.pictures;

          changeInformationSEO(record.keywords, record.description_english);

          // Gestion de la ubicacion en el mapa.
          const lng = this.profile.map_lng;
          const lat = this.profile.map_lat;
          // console.log('lng: ', lng);
          // console.log('lat: ', lat);
          this.setLocationCoordinates({ lng, lat });
          this.createMap();

          if (this.profile.youtubeprofile != null) {
            this.loadURL();
          }
          this.toTop();
        }
      });
    },
    getViewRecord() {
      let record = null;
      transportationsService.getData().then((fetch_data) => {
        record = fetch_data.value;

        if (record !== null) {
          this.transport_id = record.id;
          this.getProfile(this.transport_id);
        }
      });
    },
    async createMap() {
      try {
        mapboxgl.accessToken = this.mapBoxApiKey;
        // Create map object
        this.map = new mapboxgl.Map({
          container: this.containerID,
          style: this.mapStyle,
          center: this.location,
          zoom: 10,
        });
        // Controles basicos de navegacion: zoom y rotacion.
        this.map.addControl(new mapboxgl.NavigationControl());

        // Evento del mapa.
        this.map.on("load", () => {
          //   console.log("here");
          this.marker = new mapboxgl.Marker()
            .setLngLat(this.location)
            .addTo(this.map);
          //   console.log(this.marker);
        });
      } catch (err) {
        console.log("map error", err);
      }
    },
    setLocationCoordinates(lngLat) {
      this.location = [lngLat.lng, lngLat.lat];
    },
    sendPreference(tag_type) {
      let tagView = tag_type === "mark" ? "Me interesa" : "Me Gusta";
      preferencesService.addRecord(this.email, tag_type, 9).then((result) => {
        if (result.success) {
          this.snackText =
            tag_type === "mark"
              ? `Se ha enviado los datos de esta vista etiquedada con ${tagView}! a su buzón de correo!`
              : `La vista ha sido etiquetada con ${tagView}!`;
          // Enviar los datos por correo electronico.
          transportationsService
            .sendEmail(this.profile.id, this.email)
            .then((data) => {
              if (data.success) {
                console.log("Correo enviado: ", data);
              } else {
                console.log("Error enviando correo: ", data);
              }
            });
        } else {
          this.snackText = "Un error impidió etiquetar la vista!";
        }
        this.snackBar = true;
      });
    },
    openDialog() {
      this.email = "";
      this.dialog = true;
      //
    },
    closeDialog() {
      this.email = "";
      this.dialog = false;
    },
    acceptDialog() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.sendPreference("mark");
        this.dialog = false;
      }
    },
  },
  mounted() {
    this.getTransports();
    this.getProfile(this.$route.params.id);
  },
};
</script>

<style scoped>
.boxdivider {
  background-color: transparent;
  width: 100%;
  height: 15px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}
.bottom-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(22, 0, 235, 0.18) 0%,
    rgba(101, 99, 248, 0.911) 100%
  );
}
.my-slider > .v-slide-group__next.theme--light.v-icon {
  color: rgb(234, 10, 10) !important;
  font-size: 24pt !important;
}
.img-qr {
  height: 110px;
  width: 110px
}

.img-qr-rigth {
  width: 100%;
  background-color: #6a1b31;
  height: 110px;
  object-fit: contain
}

@media (max-width: 767px) {
  .img-qr {
    height: 60px;
    width: 60px
  }

  .img-qr-rigth {
    width: 100%;
    background-color: #6a1b31;
    height: 60px;
    object-fit: contain
  }
}

.superior-derecha {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 115px;
  height: 115px;
}

.superior-derecha-segunda {
  position: absolute;
  top: 120px;
  right: 5px;
  width: 115px;
  height: 115px;
}
.modal-email {
  padding: 30px;
}

</style>
